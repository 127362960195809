// import './wdyr'
import './index.scss'
import './setup/hotkeys'

import microApp from '@micro-zoe/micro-app'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'

import App from './App'
import { FullPageErrorFallback } from './components/Common/Common'
import AppProviders from './context'

 if (location.protocol == 'https:') {
   location.href = 'http://' + location.host
 }

microApp.start()

ReactDOM.render(
  // <React.StrictMode>
  <ErrorBoundary
    fallbackRender={({ error, resetErrorBoundary }) => {
      return <FullPageErrorFallback error={error} />
    }}>
    <AppProviders>
      <App />
    </AppProviders>
  </ErrorBoundary>,
  // </React.StrictMode>,
  document.getElementById('root')
)
