import stompjs, { Client, Subscription } from 'stompjs'

import { API_TOKEN_STORAGE_KEY } from '@/const'

import storage from '../storage'

interface SubscribeMap {
  [key: string]: Subscription[]
}

export default class Socket {
  sock: WebSocket | null = null
  stomp: Client | null = null
  subscribeMap: SubscribeMap = {} // <监听, [sub]>
  connected: boolean = false

  get getConnected() {
    return this.connected
  }

  connect(url: string) {
    this.sock = new (window as any).SockJS(
      import.meta.env.VITE_API_BASE_URL +
        url +
        (url.indexOf('?') == -1 ? '?' : '&') +
        `token=${storage.getItem(API_TOKEN_STORAGE_KEY)}`,
      {},
      {
        transports: ['websocket'],
        timeout: 5000,
      }
    )
    this.stomp = stompjs.over(this.sock as WebSocket)
    return new Promise<void>((resolve, reject) => {
      this.stomp?.connect(
        {},
        () => {
          this.connected = true
          resolve()
        },
        (e) => {
          console.error(e)
          reject()
        }
      )
    })
  }

  close() {
    if (this.stomp) {
      this.stomp.disconnect(() => {})
    }
  }

  addQueue(queue: string, callback: Fn) {
    this.add(`/user/queue/${queue}`, callback)
  }

  addTopic(queue: string, callback: Fn) {
    this.add(`/topic/${queue}`, callback)
  }

  add(dest: string, callback: Fn) {
    const sub = this.stomp?.subscribe(dest, callback)
    if (!sub) return
    this.subscribeMap[dest] = this.subscribeMap[dest] || []
    this.subscribeMap[dest].push(sub)
  }

  remove(queue: string) {
    ;(this.subscribeMap[queue] || []).forEach((sub) => sub.unsubscribe())
  }

  sendTopic(topic: string, data: any) {
    this.send(`/topic/${topic}`, data)
  }

  sendQueue(queue: string, data: any) {
    this.send(`/queue/${queue}`, data)
  }

  send(dest: string, data: any) {
    data = typeof data == 'object' ? JSON.stringify(data) : data
    this.stomp?.send(dest, {}, data)
  }
}

const socket = new Socket()

console.log(socket)

export { socket }
