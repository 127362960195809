import { IllustrationConstruction, IllustrationConstructionDark } from '@douyinfe/semi-illustrations'
import { Empty, Typography } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC } from 'react'
import { useNavigate } from 'react-router'

const Exception: FC = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <Empty
        image={<IllustrationConstruction style={{ width: 150, height: 150 }} />}
        darkModeImage={<IllustrationConstructionDark style={{ width: 150, height: 150 }} />}
        title={'盒子故障'}
        description='抱歉，请联系管理员。'>
        <Footer>
          <Typography.Text type='success' link onClick={() => navigate('/home')}>
            返回首页
          </Typography.Text>
        </Footer>
      </Empty>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Footer = styled.div`
  text-align: center;
`

export default Exception
