import { Toast } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { nanoid } from 'nanoid'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-use'

import { useAppDispatch, useAppSelector } from '@/store/hooks'
import * as appStore from '@/store/slices/app.slice'
import { updateOpening, updateRemote } from '@/store/slices/app.slice'
import { isDef, isUnDef } from '@/utils/is'
import http from '@/utils/request'
import { MenuAndFunction } from '#/client/menuAndFunction'

import Actions from './Actions/Actions'
import Avatar from './Avatar/Avatar'
import Content from './Content/Content'
import styles from './Device.module.scss'
import { EditDeviceForm } from './DeviceModal/EditDeviceModal'
import Vpn from './Vpn/Vpn'

const colors = ['blue', 'cyan', 'green', 'grey', 'indigo', 'light-blue', 'pink', 'teal']

export enum VpnState {
  PREPARING = 'preparing',
  OPENING = 'opening',
}

const fetchRemoteInfo = async (id: number) => {
  const { data: ret }: { data: DeviceRemoteInfo } = await http.get(`api/device/select/${id}`, {})
  return ret
}

const fetchMenuAndFunction = async (deviceType: string) => {
  const { data: ret }: { data: MenuAndFunction } = await http.get(`api/client/${deviceType}/all/menuAndFunction`, {})
  return ret
}

const fetchVpnInfo = async (id: number | undefined) => {
  const { data: ret }: { data: DeviceVpnInfo } = await http.get(`api/vpn/info/${id}`, {})
  return ret
}

const Device: FC<{
  device: Partial<Device>
  vpnConnectedDeviceId: Nullable<number>
  vpnStatus: string
  vpnStatusText: string
  handleClickEdit?: (device: EditDeviceForm) => void
  handleClickDelete?: (id: number) => void
  handleResetVpn?: () => void
}> = ({ device, vpnConnectedDeviceId, vpnStatus, handleResetVpn, handleClickEdit, handleClickDelete }) => {
  const { pathname } = useLocation()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const selectedDeviceId = useAppSelector(appStore.selectDeviceId)

  const openingDeviceId = useAppSelector(appStore.selectOpening)

  const [opening, setOpening] = useState(false)

  const [vpnVisible, setVpnVisible] = useState(false)

  // const [vpnInfo, setVpnInfo] = useState<DeviceVpnInfo>()
  const vpnInfoRef = useRef<DeviceVpnInfo>()

  const ipcRenderer = useRef(window.require?.('electron').ipcRenderer)

  const openVpnConf = useCallback(async () => {
    if (!ipcRenderer.current) {
      Toast.warning('Cloud环境中无法使用VPN，请使用客户端 znb-client')
      return
    }
    if (isDef(vpnConnectedDeviceId)) {
      Toast.warning('已有其他设备建立连接，请断开再重新连接此设备')
      return
    }
    if (!device.sn?.startsWith('X08')) {
      vpnInfoRef.current = await fetchVpnInfo(device.id)
    }
    setVpnVisible(true)
  }, [vpnConnectedDeviceId])

  const handleVpnCancel = useCallback(() => {
    setVpnVisible(false)
  }, [])

  const vpnAnimation = useMemo(
    () => vpnStatus && !['CLOSED', 'CONNECTED', 'ALREADY_RUNNING_VPN', 'NO_ANSWER', 'NO_VPN'].includes(vpnStatus),
    [vpnStatus]
  )

  const handleStartVpnConnect = useCallback(async (values: Record<string, any>) => {
    setVpnVisible(false)
    const { data: ret } = await http.post('api/vpn/connect', {
      data: {
        id: device.id,
        eth: values.eth,
      },
    })

    ipcRenderer.current?.invoke('startVpn', {
      vpnIp: values.vpnIp,
      mask: values.mask,
      remoteHost: ret.remote,
      remotePort: ret.remotePort,
      deviceId: device.id,
      ovpn: vpnInfoRef.current?.ovpn,
    })
  }, [])

  const handleStopVpn = useCallback(() => {
    ipcRenderer.current?.invoke('stopVpn')
    handleResetVpn?.()
  }, [])

  return (
    <>
      <div
        role='button'
        tabIndex={0}
        data-status={vpnStatus}
        data-id={device.id}
        className={classNames([
          {
            [styles.container]: true,
            [styles.containerHoverable]: !vpnAnimation,
            [styles.containerActive]:
              String(selectedDeviceId) === String(device.id) && !vpnAnimation && isUnDef(openingDeviceId),
            [styles.containerOpening]: opening && !vpnAnimation,
            [styles.containerDisable]: openingDeviceId && String(openingDeviceId) !== String(device.id),
            [styles.vpnConnecting]: vpnConnectedDeviceId === device.id && vpnAnimation,
          },
        ])}
        onClick={async () => {
          if (vpnAnimation) return
          if (openingDeviceId && String(openingDeviceId) !== String(device.id)) return
          if (!device.online) {
            Toast.warning('该设备不在线')
            return
          }
          setOpening(true)
          dispatch(updateOpening(device.id))
          navigate('/loading')
          try {
            let [{ remote, remotePort, remoteToken }, { menus, functions }] = await Promise.all([
              fetchRemoteInfo(device.id as number),
              fetchMenuAndFunction(device.type as string),
            ])
            menus = menus.map((menu) => ({ ...menu, microAppHash: `Micro_${nanoid()}` }))
            dispatch(
              updateRemote({
                remote: `${remote}:${remotePort}`,
                token: remoteToken,
                menus,
                functions,
                selectedDeviceId: device.id,
              })
            )
            navigate(
              `/device/${device.id}/${
                menus.length
                  ? menus[0].isMicroApp
                    ? `${menus[0].microAppHash}/${menus[0].routePath}`
                    : menus[0].routePath
                  : 'settings'
              }`
            )
          } catch (e) {
            navigate('/exception')
          } finally {
            setOpening(false)
            dispatch(updateOpening(undefined))
          }
        }}>
        <Avatar device={device} />
        <Content device={device} vpnConnectedDeviceId={vpnConnectedDeviceId} vpnStatus={vpnStatus} />
        <Actions
          device={device}
          vpnConnectedDeviceId={vpnConnectedDeviceId}
          handleClickVpn={openVpnConf}
          handleStopVpn={handleStopVpn}
          handleClickEdit={handleClickEdit}
          handleClickDelete={handleClickDelete}
        />
        {/* {vpnAnimation && (
          <CloseVpnContainer>
            <IconClose
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setVpnState(null)
              }}
            />
          </CloseVpnContainer>
        )} */}
      </div>
      <Vpn
        visible={vpnVisible}
        deviceId={device.id}
        vpnInfo={vpnInfoRef.current}
        handleCancel={handleVpnCancel}
        handleOk={handleStartVpnConnect}
      />
    </>
  )
}

const CloseVpnContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 101;
`

export default Device
