import { useAuth } from '@/hooks/useAuth'
import { socket } from '@/utils/socket/socket'
import { Layout } from '@douyinfe/semi-ui'
import { FC, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import styles from './AuthenticatedApp.module.scss'
import Sider from './Sider/Sider'

const BasicLayout: FC = () => {
  const { Content } = Layout

  const [init, setInit] = useState(false)

  const { userInfo } = useAuth()

  useEffect(() => {
    if (userInfo) {
      socket.connect('api/socket').finally(() => {
        setInit(true)
      })
    } else {
      setInit(true)
    }
  }, [])

  return (
    <>
      {init && (
        <Layout>
          <Sider />
          <Content className={styles.content}>
            <Outlet />
          </Content>
        </Layout>
      )}
    </>
  )
}

export default BasicLayout
