import { Button, Spin, Typography } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC } from 'react'

const FullPage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FullPageLoading: FC = () => (
  <FullPage>
    <Spin size='large' />
  </FullPage>
)

export const FullPageErrorFallback: FC<{ error: Error | null }> = ({ error }) => (
  <FullPage>
    <Typography.Text type='danger'>{error?.message}</Typography.Text>
  </FullPage>
)

export const ButtonNoPadding = styled(Button)`
  padding: 0;
`
