import { useCallback, useState } from 'react'

import { EditDeviceForm } from '@/views/Device/DeviceModal/EditDeviceModal'

export const useDeviceModal = () => {
  const [createVisible, setCreateVisible] = useState<boolean>(false)

  const [editVisible, setEditVisible] = useState<boolean>(false)

  const [editingDevice, setEditingDevice] = useState<Nullable<EditDeviceForm>>(null)

  const edit = useCallback((device) => {
    setEditingDevice(device)
  }, [])

  return {
    createVisible,
    setCreateVisible,
    editVisible,
    setEditVisible,
    editingDevice,
    edit,
  }
}
