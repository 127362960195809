import { AES, enc, MD5 } from 'crypto-js'

import { isDef } from '../is'

export const KEY = 'cloud-admin-pro'

class StorageManager {
  private namespace = ''
  private storage: Storage = localStorage

  constructor(namespace: string, storage: Storage) {
    this.namespace = namespace
    this.storage = storage
  }

  getFullKeyName(name: string) {
    return MD5(`${this.namespace}-${name}`).toString().toUpperCase()
  }

  setItem(name: string, value: any, expire: number | null = null) {
    const _val = JSON.stringify({
      value,
      expire: isDef(expire) ? +new Date() + (expire as number) : null,
    })
    const encryptedValue = AES.encrypt(_val, KEY).toString()
    this.storage.setItem(this.getFullKeyName(name), encryptedValue)
  }

  getItem(name: string, defaultValue: any = null) {
    const item = this.storage.getItem(this.getFullKeyName(name))
    if (!isDef(item)) return defaultValue

    try {
      let decryptedValue = AES.decrypt(item as string, KEY).toString(enc.Utf8)
      const data = JSON.parse(decryptedValue)
      if ([null, 0].includes(data.expire) || data.expire >= +new Date()) {
        return data.value
      }
      // this.remove(name)
    } catch (error) {
      console.log(error)
      return defaultValue
    }
    return defaultValue
  }

  key(index: number) {
    return this.storage.key(index)
  }

  remove(name: string) {
    return this.storage.removeItem(this.getFullKeyName(name))
  }

  clear(rule: string | null = null) {
    const removedKeys: (string | null)[] = []

    for (let i = 0; i < this.storage.length; i++) {
      const key = this.key(i)
      const regexp = new RegExp(`^${this.namespace}-${rule ? rule : ''}.+`, 'i')

      if (regexp.test(key as string) === false) {
        continue
      }

      removedKeys.push(key)
    }

    for (const key in removedKeys) {
      this.storage.removeItem(removedKeys[key] as string)
    }
  }
}

export default StorageManager
