import { Toast } from '@douyinfe/semi-ui'
import { useCallback, useEffect, useRef, useState } from 'react'

export const useVpn = () => {
  const ipcRenderer = useRef<any>(window.require?.('electron').ipcRenderer)

  const [vpnStatus, setVpnStatus] = useState<Nullable<string>>(null)

  const [vpnStatusText, setVpnStatusText] = useState('')

  const [connectedId, setConnectedId] = useState<Nullable<number>>(null)

  const transformVpnStatus = useCallback((vpnStatus: string) => {
    switch (vpnStatus) {
      case 'WAIT':
        return '正在与远端服务建立连接...'
      case 'AUTH':
        return '正在校验证书...'
      case 'GET_CONFIG':
        return '正在从远端获取配置...'
      case 'ASSIGN_IP':
        return '正在设置本地VPN网络设备...'
      case 'CONNECTED':
        return 'VPN连接成功'
      case 'RECONNECTING':
        return 'VPN正在尝试重新连接...'
      case 'CLOSED':
        return 'VPN已关闭，点击重新连接'
      case 'ALREADY_RUNNING_VPN':
        return '当前已有VPN连接，请关闭后再重新连接...'
      case 'NO_ANSWER':
      case 'NO_VPN':
        return '等待连接'
    }

    return ''
  }, [])

  const reset = useCallback(() => {
    setVpnStatus(null)
    setVpnStatusText('')
    setConnectedId(null)
  }, [])

  useEffect(() => {
    ipcRenderer.current?.on('status', (_: unknown, status: string, currentDeviceId: number) => {
      console.log('vpn status change:', status, currentDeviceId)
      if (['CLOSED', 'NO_ANSWER', 'NO_VPN'].includes(status)) {
        reset()
        return
      }
      setConnectedId(currentDeviceId)
      setVpnStatus(status)
      const vpnStatusText = transformVpnStatus(status)
      setVpnStatusText(vpnStatusText)
      Toast.info(vpnStatusText)
    })
  }, [])

  useEffect(() => {
    if (!ipcRenderer.current) {
      console.log('vpn disabled in cloud')
      return
    }
    const status = ipcRenderer.current?.sendSync('vpnStatus')
    console.log('vpn status:', status)
    if (status) {
      setConnectedId(status.deviceId)
      setVpnStatus(status.state)
      setVpnStatusText(transformVpnStatus(status.state))
    }
  }, [])

  return {
    vpnStatus,
    vpnStatusText,
    connectedId,
    reset,
  }
}
