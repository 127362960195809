import Icon from '@/components/Icon/Icon'
import { Typography } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC } from 'react'

const Content: FC<{ device: Partial<Device>; vpnConnectedDeviceId: Nullable<number>; vpnStatus: Nullable<string> }> = ({
  device,
  vpnConnectedDeviceId,
  vpnStatus,
}) => {
  const { Text } = Typography

  return (
    <Container>
      <Title>
        <Text ellipsis={{ showTooltip: true }} style={{ maxWidth: '140px' }}>
          {device.name || '未命名'}
        </Text>
        {device.wifi ? (
          <Icon
            name='wangxian'
            style={{ color: 'var(--semi-color-white)', marginLeft: '5px', width: '12px', height: '12px' }}
          />
        ) : (
          <Icon name='g4' style={{ color: 'var(--semi-color-white)', marginLeft: '5px' }} />
        )}
        {vpnConnectedDeviceId === device.id && vpnStatus === 'CONNECTED' && (
          <Icon name='vpn' style={{ color: 'rgba(var(--semi-green-3), 1)', marginLeft: '5px' }} />
        )}
      </Title>
      <Secondary>
        <Text size='small' type={device.online ? 'success' : 'tertiary'}>
          [{device.online ? '在线' : '离线'}]
        </Text>
        <Text
          size='small'
          type='tertiary'
          style={{ display: 'inline-block', fontSize: '12px', transform: 'scale(0.9) translate(-8px, 1px)' }}>
          {device.sn}
        </Text>
      </Secondary>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 24px 18px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const Secondary = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export default Content
