import { Button, Form, Modal } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC, useCallback } from 'react'

import http from '@/utils/request'

export type GroupSelectOption = Pick<DeviceGroup, 'id' | 'name'>

export interface CreateDeviceModalParams {
  visible: boolean
  groups?: GroupSelectOption[]
  handleOk?: () => void
  handleCancel?: () => void
}

type CreateDeviceForm = Pick<Device, 'code' | 'groupId'>

export type CreateDeviceFormValidate = { [key in keyof CreateDeviceForm]?: string }

function syncValidate(values: Record<string, any>) {
  const errors: CreateDeviceFormValidate = {}
  if (!(values as CreateDeviceForm).code) {
    errors.code = '请填写激活码'
  }
  if (!(values as CreateDeviceForm).groupId) {
    errors.groupId = '请选择设备分组'
  }
  return errors
}

async function saveDevice(code: string, groupId: number) {
  return await http.post('api/device/active', { data: { code, groupId } })
}

const CreateDeviceModal: FC<CreateDeviceModalParams> = ({ visible, groups, handleOk, handleCancel }) => {
  const submit = useCallback(async (code: string, groupId: number) => {
    await saveDevice(code, groupId)
    handleOk?.()
  }, [])

  return (
    <Modal
      title='新增设备'
      visible={visible}
      mask={false}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={null}>
      <Container>
        <Form validateFields={syncValidate} labelPosition='left' labelWidth={80}>
          {({ formState, values, formApi }) => (
            <>
              <Form.TextArea field='code' label='激活码' trigger='blur'></Form.TextArea>
              <Form.Select
                field='groupId'
                label='设备分组'
                initValue={-1}
                placeholder='请选择设备分组'
                style={{ width: '100%' }}>
                {groups?.map((group) => (
                  <Form.Select.Option key={group.id} value={group.id}>
                    {group.name}
                  </Form.Select.Option>
                ))}
              </Form.Select>
              <div style={{ textAlign: 'right', marginTop: '10px' }}>
                <Button
                  type='primary'
                  disabled={!values.code || !values.groupId}
                  onClick={() => submit(values.code, values.groupId)}>
                  提交
                </Button>
              </div>
            </>
          )}
        </Form>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 32px;
`

export default CreateDeviceModal
