/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import styled from '@emotion/styled'
import jsxCustomEvent from '@micro-zoe/micro-app/polyfill/jsx-custom-event'
import { FC, useMemo, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@/store/hooks'
import * as appStore from '@/store/slices/app.slice'

import Exception from '../Exception/Exception'
import Loading from '../Loading/Loading'

const MicroApp: FC<{ baseRouter?: string }> = ({ baseRouter }) => {
  console.log(jsxCustomEvent)

  const dispatch = useAppDispatch()

  const props = useAppSelector(appStore.selectRemote)

  const src = useMemo(() => `http://${props.remote}/`, [props.remote])

  const [loading, setLoading] = useState<boolean>(true)

  const [error, setError] = useState<boolean>(false)

  const appRef = useRef<HTMLElement | null>(null)

  function handleColor() {
    const appBodyEl = (appRef.current as HTMLElement).querySelector('micro-app-body')
    if (!appBodyEl || !appBodyEl.querySelector('span')) {
      requestAnimationFrame(handleColor)
      return
    }
      appBodyEl.setAttribute("style",'height: 100%;')
    const spanEls = appBodyEl.querySelectorAll('span')
    if (spanEls) {
      let blackCount = 0
      spanEls.forEach((span) => {
        blackCount +=
          getComputedStyle(span)
            ?.color.replace(/(rgb\(|rgba\(|\))/g, '')
            ?.split(',')
            ?.map((color) => Number(color))
            ?.reduce((prev, next) => prev + next, 0) < 240
            ? 1
            : 0
      })
      if (blackCount > spanEls.length / 2) {
        if (appRef.current) {
          // appRef.current.style.filter = 'invert(100%)'
          appRef.current.style.height = '100%'
          const style = document.createElement('style')
          style.id = 'imgInvert'
          style.innerText = `micro-app img {filter: invert(1);}`
          appRef.current.querySelector('micro-app-head')?.appendChild(style)
        }
      } else {
        if (appRef.current) {
          appRef.current.style.filter = 'unset'
          appRef.current.querySelector('#imgInvert')?.remove()
        }
      }
    }
  }

  return (
    <Container>
      <micro-app
        ref={appRef}
        // name={baseRouter?.split('/')?.[2]}
        name={'subApp'}
        url={src}
        baseroute={baseRouter}
        data={{ layout: 'topmenu', token: props.token, remote: src }}
        onCreated={() => dispatch(appStore.updateOpening(props.selectDeviceId))}
        onMounted={() => {
          setLoading(false)
          dispatch(appStore.updateOpening(undefined))
          handleColor()
        }}
        onError={() => {
          setLoading(false)
          setError(true)
          dispatch(appStore.updateOpening(undefined))
        }}></micro-app>
      {loading && !error && <Loading content='～正在努力进入系统，请稍候～' />}
      {error && !loading && <Exception />}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default MicroApp
