import { CSSProperties, FC, HTMLAttributes, useMemo } from 'react'

import styles from './Icon.module.scss'

export interface IconProps {
  name: string
  prefix?: string
  style?: CSSProperties
}

const Icon: FC<HTMLAttributes<any> & IconProps> = (props) => {
  const getIconName = useMemo(() => `#${props.prefix || 'icon-'}${props.name}`, [props.prefix, props.name])

  return (
    <div className={styles.icon} style={props.style}>
      <svg className={styles.svg}>
        <use href={getIconName}></use>
      </svg>
    </div>
  )
}

export default Icon
