import { API_TOKEN_STORAGE_KEY } from '@/const'
import http from '@/utils/request'
import storage from '@/utils/storage'

export interface UserInfo {
  id: string
  phone: string
  token: string
  enableWebShell: boolean
  isAdmin: boolean
  customer: {
    icon: string
    name: string
    domainTitle: string
  }
}

export interface AuthForm {
  mobile: string
  code: string
}

export function login({ mobile, code }: AuthForm): Promise<string> {
  return new Promise<string>((resolve, reject) =>
    http
      .post('api/user/login', { data: { mobile, code } })
      .then(({ data: ret }) => {
        storage.setItem(API_TOKEN_STORAGE_KEY, ret.token || '')
        resolve(ret.token)
      })
      .catch((e) => {
        reject(e)
      })
  )
}

export function scanLogin(user: UserInfo) {
  storage.setItem(API_TOKEN_STORAGE_KEY, user.token || '')
}

export async function logout() {
  storage.remove(API_TOKEN_STORAGE_KEY)
  return new Promise<void>((resolve, reject) => {
    http
      .get(`api/user/logout`, {})
      .then(() => {
        resolve()
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export function bootstrapUser(): Promise<UserInfo> {
  return new Promise<UserInfo>((resolve, reject) => {
    http
      .get('api/user/info', {})
      .then(({ data: ret }) => {
        if (ret?.customer?.domainTitle) {
          document.title = ret.customer.domainTitle
        }
        if (ret?.customer?.icon) {
          var favicon: HTMLLinkElement | null = document.querySelector('link[rel="icon"]')
          if (favicon) {
            favicon.href = ret.customer.icon
          }
        }
        resolve(ret)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
