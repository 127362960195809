import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import AppReducer from './slices/app.slice'
import userReducer from './slices/auth.slice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    app: AppReducer,
  },
  devTools: true,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
