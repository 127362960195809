import { FC, useEffect } from 'react'

import Icon from '@/components/Icon/Icon'
import { useAuth } from '@/hooks/useAuth'
import { useAppDispatch } from '@/store/hooks'
import { resetRemote } from '@/store/slices/app.slice'

import Contact from './Contact/Contact'
import styles from './Home.module.scss'
import Notice from './Notice/Notice'
import Stat from './Stat/Stat'

const Home: FC = () => {
  const { userInfo } = useAuth()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resetRemote())
  }, [])

  return (
    <div className={styles.page}>
      <div className={styles.welcome}>
        <Icon
          name='welcome'
          style={{
            width: '24px',
            height: '24px',
          }}
        />
        <span>欢迎登录，{userInfo?.customer?.domainTitle}</span>
      </div>
      <Stat />
      <div className={styles.content}>
        <Notice />
      </div>
      <Contact />
    </div>
  )
}

export default Home
