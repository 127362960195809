import { Button, Form, Modal } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC, useCallback } from 'react'

import http from '@/utils/request'

export interface GroupModalParams {
  groupId: Nullable<number>
  groupName: Nullable<string>
  visible: boolean
  handleOk?: () => void
  handleCancel?: () => void
}

type GroupForm = Pick<DeviceGroup, 'name' | 'id'>

export type GroupFormValidate = { [key in keyof GroupForm]?: string }

function syncValidate(values: Record<string, any>) {
  const errors: GroupFormValidate = {}
  if (!(values as GroupForm).name) {
    errors.name = '请填写分组名称'
  }
  return errors
}

async function saveGroup(id: Nullable<number>, name: string, sort: number) {
  const url = `api/device/group/${id ? 'edit' : 'add'}`
  const payload = { data: id ? { id, name, sort } : { name, sort } }
  return await http.post.apply(http, [url, payload])
}

const GroupModal: FC<GroupModalParams> = ({ groupId, groupName, sort, visible, handleOk, handleCancel }) => {
  const submit = useCallback(
    async (name: string, sort: number) => {
      await saveGroup(groupId, name, sort)
      handleOk?.()
    },
    [groupId]
  )

  return (
    <Modal
      title={groupId ? '修改分组' : '新增分组'}
      visible={visible}
      mask={false}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={null}>
      <Container>
        <Form validateFields={syncValidate} labelPosition='left' labelWidth={80}>
          {({ formState, values, formApi }) => (
            <>
              <Form.Input
                field='name'
                label='分组名称'
                initValue={groupName}
                placeholder='请输入分组名称'
                trigger='blur'></Form.Input>
              <Form.Input
                field='sort'
                label='排序数值'
                type='number'
                initValue={sort}
                placeholder='请输入排序数值,可选'
                trigger='blur'></Form.Input>
              <div style={{ textAlign: 'right', marginTop: '10px' }}>
                <Button type='primary' disabled={!values.name} onClick={() => submit(values.name, values.sort)}>
                  提交
                </Button>
              </div>
            </>
          )}
        </Form>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 32px;
`

export default GroupModal
