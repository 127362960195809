import { AxiosRequestConfig, Method } from 'axios'
import { omit as Omit } from 'lodash-es'

import axios from './axios'
import { Http, Option } from './types'

const __objToUrlParam = (obj: Record<string, any>) => {
  if (!obj || !Object.keys(obj).length) {
    return ''
  }
  return (
    '?' +
    Object.keys(obj)
      .map((key) => {
        return `${key}=${encodeURIComponent(obj[key])}`
      })
      .join('&')
  )
}

const __request = (method: Method = 'GET', api = '', options: Option = {}) => {
  const { uri, params = {} } = options

  let url = `/${api}`

  if (uri) {
    url = `${url}/${uri}`
  }

  url = url + __objToUrlParam(params)

  const __options = Omit(options, ['uri', 'params'])

  const config: AxiosRequestConfig = {
    method,
    url,
    ...__options,
  }

  return axios(config)
}

const doGet = (api: string, options: Option) => {
  return __request('get', api, options)
}

const doPost = (api: string, options: Option) => {
  return __request('post', api, options)
}

const doPut = (api: string, options: Option) => {
  return __request('put', api, options)
}

const doDelete = (api: string, options: Option) => {
  return __request('delete', api, options)
}

const doPatch = (api: string, options: Option) => {
  return __request('patch', api, options)
}

const doHead = (api: string, options: Option) => {
  return __request('head', api, options)
}

const doOptions = (api: string, options: Option) => {
  return __request('options', api, options)
}

const fetchFile = (method: Method, api: string, fileName: string, options: Option): Promise<void> => {
  return new Promise((resolve, reject) => {
    __request(method, api, {
      ...options,
      responseType: 'blob',
    })
      .then((ret) => {
        const data = ret.data
        if (!data) {
          return
        }
        const url = window.URL.createObjectURL(new Blob([data]))
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.setAttribute('download', fileName)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
        resolve()
      })
      .catch((error) => reject(error))
  })
}

const download = (url: string, fileName: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download', fileName)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      resolve()
    } catch (e) {
      reject(e)
    }
  })
}

const http: Http = {
  get: (api: string, option: Option) => doGet(api, { ...option }),
  GET: (api: string, option: Option) => doGet(api, { ...option }),
  post: (api: string, option: Option) => doPost(api, { ...option }),
  POST: (api: string, option: Option) => doPost(api, { ...option }),
  put: (api: string, option: Option) => doPut(api, { ...option }),
  PUT: (api: string, option: Option) => doPut(api, { ...option }),
  delete: (api: string, option: Option) => doDelete(api, { ...option }),
  DELETE: (api: string, option: Option) => doDelete(api, { ...option }),
  options: (api: string, option: Option) => doOptions(api, { ...option }),
  OPTIONS: (api: string, option: Option) => doOptions(api, { ...option }),
  head: (api: string, option: Option) => doHead(api, { ...option }),
  HEAD: (api: string, option: Option) => doHead(api, { ...option }),
  patch: (api: string, option: Option) => doPatch(api, { ...option }),
  PATCH: (api: string, option: Option) => doPatch(api, { ...option }),
  fetchFile: (method: Method, api: string, fileName: string, option: Option) =>
    fetchFile(method, api, fileName, { ...option }),
  download,
}

export default http
