import { Button, Form, InputGroup, TabPane, Tabs, Toast, useFieldState } from '@douyinfe/semi-ui'
// @ts-ignore
import QRCode from 'qrcode'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useInterval, useMount } from 'react-use'

import { useAppDispatch } from '@/store/hooks'
import { resetRemote } from '@/store/slices/app.slice'
import { login, scan } from '@/store/slices/auth.slice'
import { checkMobileIsValid } from '@/utils'
import http from '@/utils/request'

import Bind from './Bind'
import styles from './Login.module.scss'

const Login: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [seconds, setSeconds] = useState<number>(60)
  const [timing, setTiming] = useState<boolean>(false)
  const [bind, setBind] = useState<{ scan: string; visible: boolean }>({ scan: '', visible: false })
  const start = useRef<number>(0)
  const timer = useRef<ReturnType<typeof requestAnimationFrame> | null>(null)

  const startTimer = useCallback(() => {
    timer.current = requestAnimationFrame(() => {
      const current = +new Date()
      const diff = 60 - Math.round((current - start.current) / 1000)
      if (diff > 0 && diff <= 60) {
        setSeconds(diff)
        startTimer()
      } else if (diff <= 0) {
        cancelAnimationFrame(timer.current as ReturnType<typeof requestAnimationFrame>)
        setTiming(false)
      }
    })
  }, [])

  const getCode = useCallback(async (mobile: string) => {
    if (!mobile || !checkMobileIsValid(mobile)) {
      Toast.error('请输入正确的手机号')
      return
    }
    await http
      .post('api/user/login/send', {
        data: {
          mobile,
        },
      })
      .catch(() => {
        Toast.error('获取验证码失败，请重试')
      })
    start.current = +new Date()
    setSeconds(60)
    setTiming(true)
    startTimer()
  }, [])

  const handleSubmit = useCallback(async (values: { mobile: string; code: string }) => {
    await dispatch(login(values))
    cancelAnimationFrame(timer.current as ReturnType<typeof requestAnimationFrame>)
    navigate('/home')
  }, [])

  const VerifyButton = () => {
    const fieldState = useFieldState('mobile')
    return (
      <Button
        type='tertiary'
        size='large'
        disabled={timing}
        style={{ width: '120px', marginLeft: '8px' }}
        onClick={() => {
          !timing && getCode(fieldState.value)
        }}>
        {timing ? `${seconds}秒后可获取` : '发送验证码'}
      </Button>
    )
  }

  useEffect(() => {
    dispatch(resetRemote())
  }, [])

  // 扫码登录
  useMount(async () => {
    const { data } = await http.get(`/api/wx/url/login`, {})
    requestAnimationFrame(() => {
      setBind({ ...bind, scan: data.key })
      localStorage.setItem('scanToken', data.key)
      QRCode.toCanvas(
        document.getElementById('wxLogin'),
        data.value,
        { width: 180, height: 180 },
        function (error: any) {
          if (error) console.error(error)
          console.log('success!')
        }
      )
    })
  })
  useInterval(
    async () => {
      const { data } = await http.get(`/api/wx/url/login/result?code=${bind.scan}`, {})
      if (!data) {
        return
      }
      // 已绑定过用户直接进入
      if (data.value) {
        await dispatch(scan(data.value))
        cancelAnimationFrame(timer.current as ReturnType<typeof requestAnimationFrame>)
        navigate('/home')
      }
      // 未绑定, 需要进行二绑
      else {
        setBind({ scan: data.key, visible: true })
      }
    },
    bind.visible ? null : 2000
  )

  return (
    <div className={styles.page}>
      <Bind
        {...bind}
        onClose={() => {
          setBind({ ...bind, visible: false })
        }}
      />
      {/* <Header /> */}
      {/* <Background /> */}
      {/* <Container> */}
      <div className={styles.login}>
        <Tabs>
          <TabPane tab='微信扫码登录' itemKey='1'>
            <div style={{ textAlign: 'center', padding: '16px' }}>
              <canvas id='wxLogin'></canvas>
            </div>
          </TabPane>
          <TabPane tab='手机短信登录' itemKey='2'>
            <Form onSubmit={(values) => handleSubmit(values as any)} style={{ width: '320px' }}>
              {({ formState, values, formApi }) => (
                <>
                  <Form.Input
                    field='mobile'
                    label='您的手机号'
                    noLabel={true}
                    style={{ width: '320px' }}
                    size='large'
                    placeholder='输入您的手机号'></Form.Input>
                  <InputGroup style={{ width: '320px', marginTop: '20px' }} size='large'>
                    <Form.Input
                      field='code'
                      label='短信验证码'
                      size='large'
                      noLabel={true}
                      style={{ width: '192px' }}
                      placeholder='输入验证码'></Form.Input>
                    <VerifyButton />
                  </InputGroup>
                  <Button
                    disabled={!values.mobile || !values.code}
                    htmlType='submit'
                    type='tertiary'
                    size='large'
                    block
                    style={{ marginTop: '32px' }}>
                    立即登录
                  </Button>
                </>
              )}
            </Form>
          </TabPane>
        </Tabs>
      </div>
      {/* </Container> */}
    </div>
  )
}

export default Login
