import { IconDoubleChevronLeft, IconDoubleChevronRight } from '@douyinfe/semi-icons'
import styled from '@emotion/styled'
import { FC, HTMLProps } from 'react'

const Trigger: FC<HTMLProps<HTMLDivElement> & { collapsed: boolean }> = ({ collapsed, onClick }) => {
  return (
    <Container style={{ left: collapsed ? '0' : '333px' }} onClick={(e) => onClick?.(e)}>
      {collapsed ? <IconDoubleChevronRight /> : <IconDoubleChevronLeft />}
    </Container>
  )
}

const Container = styled('div')({
  width: '16px',
  height: '50px',
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
  background: 'var(--semi-color-bg-1)',
  position: 'absolute',
  top: '50%',
  left: '333px',
  transform: 'translateY(-25px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'var(--semi-color-text-3)',
  cursor: 'pointer',
  zIndex: 2,
})

export default Trigger
