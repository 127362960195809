import { useCallback, useState } from 'react'

export const useGroupModal = () => {
  const [visible, setVisible] = useState<boolean>(false)

  const [editingGroupId, setEditingGroupId] = useState<Nullable<number>>(null)

  const [editingGroupName, setEditingGroupName] = useState<Nullable<string>>('')

  const [editingGroupSort, setEditingGroupSort] = useState<Nullable<number>>(null)

  const edit = useCallback((id: Nullable<number>, name: Nullable<string>, sort: Nullable<number>) => {
    setEditingGroupId(id)
    setEditingGroupName(name)
    setEditingGroupSort(sort)
  }, [])

  return {
    visible,
    setVisible,
    editingGroupId,
    editingGroupName,
    editingGroupSort,
    edit,
  }
}
