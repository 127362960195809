import http from '@/utils/request'
import { socket } from '@/utils/socket/socket'
import CreateDeviceModal from '@/views/Device/DeviceModal/CreateDeviceModal'
import EditDeviceModal, { EditDeviceForm } from '@/views/Device/DeviceModal/EditDeviceModal'
import GroupModal from '@/views/DeviceGroup/GroupModal/GroupModal'
import { IconSearch } from '@douyinfe/semi-icons'
import { Button, Input, Layout, Toast } from '@douyinfe/semi-ui'
import { pick } from 'lodash-es'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import DeviceGroup from '../../DeviceGroup/DeviceGroup'
import Refresh from '../../DeviceGroup/Refresh/Refresh'
import Header from '../Header/Header'
import styles from './Sider.module.scss'
import Trigger from './Trigger'
import { useDeviceModal } from './useDeviceModal'
import { useGroupModal } from './useGroupModal'
import { useVpn } from './useVpn'

const fetchDeviceGroup = async (keyword: string | null) => {
  const { data: ret } = await http.post('api/device/list/group', {
    data: {
      name: keyword,
      sn: keyword,
    },
  })
  return ret?.map((group: DeviceGroup) => {
    return {
      ...group,
      deviceList: group?.deviceList.sort(
        (a: Device, b: Device) => Number(b.online || false) - Number(a.online || false)
      ),
    }
  })
}

const deleteGroup = async (id: Nullable<number>) => {
  await http.post('api/device/group/delete', { data: { idList: [id] } })
}

const deleteDevice = async (id: Nullable<number>) => {
  await http.post('api/device/delete', { data: { idList: [id] } })
}

const Sider: FC = () => {
  const [keyword, setKeyword] = useState<string | null>(null)

  const { isLoading, isFetching, data, refetch } = useQuery<DeviceGroup[]>(
    ['device-groups', keyword],
    async () => await fetchDeviceGroup(keyword),
    {
      refetchOnWindowFocus: false,
    }
  )

  const groups = useMemo(() => data?.map((group) => pick(group, 'name', 'id')), [data])

  useEffect(() => {
    socket.addQueue(`device`, (evt: any) => {
      // const device: Device = JSON.parse(evt.body)
      refetch()
    })
  }, [])

  const {
    visible: groupModalVisible,
    setVisible: setGroupModalVisible,
    editingGroupId,
    editingGroupName,
    editingGroupSort,
    edit: editGroup,
  } = useGroupModal()

  const handleDeleteGroup = useCallback(async (groupId: number) => {
    await deleteGroup(groupId)
    Toast.success('删除成功')
    refetch()
  }, [])

  const {
    createVisible: createDeviceVisible,
    setCreateVisible: setCreateDeviceVisible,
    editVisible: editDeviceVisible,
    setEditVisible: setEditDeviceVisible,
    editingDevice,
    edit: editDevice,
  } = useDeviceModal()

  const handleDeleteDevice = useCallback(async (deviceId: number) => {
    await deleteDevice(deviceId)
    Toast.success('删除成功')
    refetch()
  }, [])

  const { vpnStatus, vpnStatusText, connectedId, reset } = useVpn()

  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <>
      <Layout.Sider className={styles.container} style={{ display: collapsed ? 'none' : 'block' }}>
        <Header />
        <Input
          className={styles.search}
          placeholder='输入名称/SN号进行搜索'
          prefix={<IconSearch />}
          size='large'
          showClear
          onEnterPress={(e: any) => {
            setKeyword(e.target.value)
          }}></Input>
        <div className={styles.toolbar}>
          <div>
            <Button type='tertiary' onClick={() => setCreateDeviceVisible(true)}>
              新建设备
            </Button>
            <Button
              type='tertiary'
              onClick={() => {
                editGroup(null, null)
                setGroupModalVisible(true)
              }}
              style={{ marginLeft: '10px' }}>
              新建分组
            </Button>
          </div>
          <Refresh
            animation={isLoading || isFetching}
            onClick={(e) => {
              refetch()
            }}
          />
        </div>
        <DeviceGroup
          data={data}
          vpnConnectedDeviceId={connectedId}
          vpnStatus={vpnStatus || ''}
          vpnStatusText={vpnStatusText}
          handleEditGroup={(groupId, groupName, sort) => {
            editGroup(groupId, groupName, sort)
            setGroupModalVisible(true)
          }}
          handleDeleteGroup={handleDeleteGroup}
          handleEditDevice={(device) => {
            editDevice(device)
            setEditDeviceVisible(true)
          }}
          handleDeleteDevice={handleDeleteDevice}
          handleResetVpn={() => {
            // reset()
          }}
        />
      </Layout.Sider>
      <GroupModal
        visible={groupModalVisible}
        groupId={editingGroupId}
        groupName={editingGroupName}
        sort={editingGroupSort}
        handleCancel={() => setGroupModalVisible(false)}
        handleOk={() => {
          setGroupModalVisible(false)
          refetch()
        }}
      />
      <CreateDeviceModal
        visible={createDeviceVisible}
        groups={groups}
        handleCancel={() => setCreateDeviceVisible(false)}
        handleOk={() => {
          setCreateDeviceVisible(false)
          refetch()
        }}
      />
      <EditDeviceModal
        visible={editDeviceVisible}
        device={editingDevice || ({} as EditDeviceForm)}
        groups={groups}
        handleCancel={() => setEditDeviceVisible(false)}
        handleOk={() => {
          setEditDeviceVisible(false)
          refetch()
        }}
      />
      <Trigger
        collapsed={collapsed}
        onClick={() => {
          setCollapsed(!collapsed)
        }}
      />
    </>
  )
}

export default Sider
