import { IconChevronDown, IconChevronRight, IconDelete, IconEdit } from '@douyinfe/semi-icons'
import { Collapse, Popconfirm, Toast } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC } from 'react'

import Device from '../Device/Device'
import { EditDeviceForm } from '../Device/DeviceModal/EditDeviceModal'
import styles from './DeviceGroup.module.scss'

export interface DeviceGroupParmas {
  data?: DeviceGroup[]
  vpnConnectedDeviceId: Nullable<number>
  vpnStatus: string
  vpnStatusText: string
  handleEditGroup?: (groupId: number, groupName: string) => void
  handleDeleteGroup?: (groupId: number) => void
  handleEditDevice?: (device: EditDeviceForm) => void
  handleDeleteDevice?: (id: number) => void
  handleResetVpn?: () => void
}

const DeviceGroup: FC<DeviceGroupParmas> = (props) => {
  return (
    <Container>
      <Collapse
        expandIconPosition='left'
        defaultActiveKey='-1'
        expandIcon={<IconChevronRight />}
        collapseIcon={<IconChevronDown />}>
        {props.data &&
          props.data.map((group, groupIndex) => {
            return (
              <Collapse.Panel
                key={group.id}
                header={
                  <div className={styles.header}>
                    <span>{groupIndex > 0 ? groupIndex  + '. ' : ''}{group.name}</span>
                    {group.id !== -1 && (
                      <div className={styles.actions}>
                        <IconEdit
                          className={styles.actionsIcon}
                          onClick={(e) => {
                            e.stopPropagation()
                            props.handleEditGroup?.(group.id, group.name, group.sort)
                          }}
                        />
                        <Popconfirm
                          title='确定要删除此分组？'
                          content='此修改将不可逆'
                          cancelText='确定'
                          okText='我再考虑考虑'
                          onCancel={(e) => {
                            e.stopPropagation()
                            if (group.id === -1) {
                              Toast.warning('[默认] 不可移除！')
                              return
                            }
                            if (group.deviceList?.length) {
                              Toast.warning('该分组下有设备，不可移除！')
                              return
                            }
                            props.handleDeleteGroup?.(group.id)
                          }}>
                          <IconDelete
                            className={styles.actionsIcon}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          />
                        </Popconfirm>
                      </div>
                    )}
                  </div>
                }
                itemKey={group.id + ''}>
                {group.deviceList.map((device) => {
                  return (
                    <Device
                      key={device.id}
                      vpnConnectedDeviceId={props.vpnConnectedDeviceId}
                      vpnStatus={props.vpnStatus}
                      vpnStatusText={props.vpnStatusText}
                      device={device}
                      handleClickEdit={(device) => props.handleEditDevice?.({ ...device, groupId: group.id })}
                      handleClickDelete={props.handleDeleteDevice}
                      handleResetVpn={() => props.handleResetVpn?.()}
                    />
                  )
                })}
              </Collapse.Panel>
            )
          })}
      </Collapse>
    </Container>
  )
}

const Container = styled.div`
  height: calc(100vh - 59px - 38px - 32px - 12px - 30px);
  overflow-y: auto;
`

export default DeviceGroup
