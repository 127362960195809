import { Typography } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC } from 'react'

const outsideColor = '#3be6cb'
const insideColor = '#02bcfe'
const duration = 2
const outsideColorAnimation = `${outsideColor};${insideColor};${outsideColor}`
const insideColorAnimation = `${insideColor};${outsideColor};${insideColor}`

const Loading: FC<{ content?: string }> = ({ content }) => {
  return (
    <Container>
      <svg width='50' height='50' viewBox='0 0 50 50' preserveAspectRatio='xMidYMid meet'>
        <circle
          cx='25'
          cy='25'
          r='22'
          fill='none'
          strokeWidth='3'
          stroke={outsideColor}
          strokeDasharray='34'
          strokeLinecap='round'>
          <animateTransform
            attributeName='transform'
            type='rotate'
            values='0 25 25;360 25 25'
            dur={`${duration}s`}
            repeatCount='indefinite'
          />
          <animate
            attributeName='stroke'
            values={outsideColorAnimation}
            dur={`${+duration * 2}s`}
            repeatCount='indefinite'
          />
        </circle>
        <circle
          cx='25'
          cy='25'
          r='12'
          fill='none'
          strokeWidth='3'
          strokeDasharray='19'
          stroke={insideColor}
          strokeLinecap='round'>
          <animateTransform
            attributeName='transform'
            type='rotate'
            values='360 25 25;0 25 25'
            dur={`${duration}s`}
            repeatCount='indefinite'
          />
          <animate
            attributeName='stroke'
            values={insideColorAnimation}
            dur={`${+duration * 2}s`}
            repeatCount='indefinite'
          />
        </circle>
      </svg>
      <Typography.Title heading={6} style={{ marginTop: '20px' }}>
        {content || '～正在努力打开系统，请稍候～'}
      </Typography.Title>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default Loading
