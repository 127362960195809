// @ts-nocheck
import { IconBell } from '@douyinfe/semi-icons'
import { IllustrationNoContentDark } from '@douyinfe/semi-illustrations'
import { Avatar, Empty, List, Typography } from '@douyinfe/semi-ui'
import { format } from 'date-fns'
import { FC } from 'react'
import { useQuery } from 'react-query'

import http from '@/utils/request'

import styles from './Notice.module.scss'

export interface NoticeModel {
  id: number
  title: string
  content: string
  createTime: string
}

const fetchNotices = async () => {
  const { data: ret } = await http.post('api/notice/find', { data: { isEnable: true, size: 1000, title: null } })
  return ret ? ret.content : []
}

const Notice: FC = () => {
  const { isLoading, isFetching, data } = useQuery<NoticeModel[]>('notices', fetchNotices, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
  const { Title, Text, Paragraph } = Typography
  return (
    <div className={styles.container}>
      <h1>系统公告</h1>
      <List
        bordered
        className={styles.list}
        dataSource={data}
        loading={isLoading || isFetching}
        emptyContent={
          <Empty
            darkModeImage={<IllustrationNoContentDark style={{ width: 200, height: 200 }} />}
            title='Oh~'
            description='暂时没有公告哦~'></Empty>
        }
        renderItem={(item) => (
          <List.Item
            header={
              <Avatar color='cyan'>
                <IconBell />
              </Avatar>
            }
            extra={<Text>{format(item.createTime, 'yyyy-MM-dd HH:mm:ss')}</Text>}
            main={
              <div>
                <Title heading={5} type='primary'>
                  {item.title}
                </Title>
                <Paragraph type='tertiary' style={{ marginTop: '6px' }}>
                  {item.content}
                </Paragraph>
              </div>
            }
          />
        )}
      />
    </div>
  )
}

export default Notice
