import { Spin } from '@douyinfe/semi-ui'
import { FC, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import Client from './client'

const WebSSH: FC = () => {
  const [showLoading, setShowLoading] = useState(true)
  const client = useRef<Client>()
  const { id } = useParams()
  useEffect(() => {
    client.current = new Client(id as any)
    client.current.connect()
    setTimeout(() => {
      setShowLoading(false)
    }, 5 * 1000)
    return () => {
      client.current?.disconnect()
    }
  }, [])
  return (
    <>
      <div style={{ width: '100vw', height: '100vh', display: 'flex' }}>
        <div id='xterm' style={{ flex: 1 }}></div>
      </div>
      <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
        {showLoading && <Spin size='large' />}
      </div>
    </>
  )
}

export default WebSSH
