import { storage as stroageConfig } from '@/const'

import StorageManager from './storage'

const { namespace, type } = stroageConfig

const targetStorage = type === 'local' ? localStorage : sessionStorage

const storage = new StorageManager(namespace, targetStorage)

export default storage
