import logo from '@/assets/imgs/logo.png'
import { FC, HTMLAttributes } from 'react'

const Logo: FC = (props: { icon: string, children: any }) => {
  return <img src={props.icon} width='32' height='32' />
  // return (
  // <svg
  //   viewBox='0 0 1024 1024'
  //   version='1.1'
  //   xmlns='http://www.w3.org/2000/svg'
  //   p-id='6752'
  //   width='32'
  //   height='32'
  //   style={{ color: 'rgba(var(--semi-blue-9), 1)' }}>
  //   <path
  //     d='M529.398 524.428l-1.243 413.838 356.672-208.783v-413.838z'
  //     fill='currentColor'
  //     stroke='rgba(53, 54, 60, 1)'></path>
  //   <path
  //     d='M512 490.873l354.186-207.541-354.186-206.298-354.186 206.298z'
  //     fill='currentColor'
  //     stroke='rgba(53, 54, 60, 1)'></path>
  //   <path
  //     d='M492.116 521.942l-352.943-206.298v413.838l351.699 205.055z'
  //     fill='currentColor'
  //     stroke='rgba(53, 54, 60, 1)'></path>
  // </svg>
  // )
}

export default Logo
