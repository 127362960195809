import { FC, ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'

import { store } from '@/store'

const AppProviders: FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = new QueryClient({})

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </Provider>
  )
}

export default AppProviders
