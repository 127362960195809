import { IconHome } from '@douyinfe/semi-icons'
import { Button, Nav } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import microApp from '@micro-zoe/micro-app'
import { camelCase } from 'lodash-es'
import { FC, useMemo, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router'

import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { resetRemote, selectRemote } from '@/store/slices/app.slice'
import Settings from '@/views/Settings/Settings'
import SubApp from '@/views/SubApp/SubApp'
import { Menu } from '#/client/menuAndFunction'

const InnerLayout: FC = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { menus } = useAppSelector(selectRemote)

  const subAppMicroMenus = useMemo(() => menus.filter((menu) => menu.isSubRoute), [menus])

  const mainAppMenus = useMemo<Record<string, Menu>>(
    () => menus.filter((menu) => !menu.isSubRoute).reduce((prev, next) => ({ ...prev, [next.routePath]: next }), {}),
    [menus]
  )

  const navKeyMap = useMemo<Record<string, string>>(
    () =>
      subAppMicroMenus.reduce(
        (prev, next) => ({ ...prev, [next.microAppHash as string]: `${next.microAppHash}/${next.routePath}` }),
        {}
      ),
    [subAppMicroMenus]
  )

  const { pathname } = useLocation()

  const key = useMemo(() => {
    const temp = pathname.split('/')
    return temp[temp.length - 1]
  }, [pathname])

  const [selectedKeys, setSelectedKeys] = useState(() => {
    const temp = pathname.split('/')
    return pathname.includes('Micro_') ? [navKeyMap[temp[temp.length - 2]]] : [temp[temp.length - 1]]
  })

  return (
    <div>
      <Nav
        mode='horizontal'
        selectedKeys={selectedKeys}
        onSelect={({ itemKey }) => {
          setSelectedKeys([itemKey as string])
          if (pathname.includes('Micro_') && itemKey.toString().includes('Micro_')) {
            const [name, routePath] = itemKey.toString().split('/')
            microApp.setData('subApp', { path: routePath })
          } else {
            navigate(itemKey as string, {})
          }
        }}
        style={{ height: '48px', paddingLeft: '10px', paddingRight: '10px' }}>
        {subAppMicroMenus.map((menu) => {
          const key = menu.routePath
          return (
            <Nav.Item
              key={menu.isMicroApp ? `${menu.microAppHash}/${key}` : key}
              itemKey={menu.isMicroApp ? `${menu.microAppHash}/${key}` : key}
              text={menu.title}
              icon={<Icon dangerouslySetInnerHTML={{ __html: menu.icon }} />}
            />
          )
        })}
        {mainAppMenus['settings'] && (
          <Nav.Item
            itemKey='settings'
            text='基本信息'
            icon={<Icon dangerouslySetInnerHTML={{ __html: mainAppMenus['settings'].icon }} />}
          />
        )}
        <Nav.Footer>
          <Button
            theme='borderless'
            type='tertiary'
            icon={<IconHome size='large' />}
            onClick={() => {
              navigate('/home')
            }}
          />
        </Nav.Footer>
      </Nav>
      <Routes>
        {subAppMicroMenus.map((menu) => {
          const key = menu.routePath
          return (
            <Route
              path={menu.isMicroApp ? `${menu.microAppHash}/*` : `/${key}`}
              key={key}
              element={
                <SubApp
                  isMicroApp={menu.isMicroApp}
                  baseRoute={`device/${id}/${menu.microAppHash}`}
                  routePath={menu.routePath}
                />
              }
            />
          )
        })}
        {/* <Route path='micro/*' element={<SubApp isMicroApp={true} />} /> */}
        {mainAppMenus['settings'] && <Route path='/settings' element={<Settings />} />}
        {/* <Navigate to={window.location.pathname + `${subAppMenus.length ? subAppMenus[0].routePath}`} /> */}
      </Routes>
    </div>
  )
}

const Icon = styled('div')({
  width: '20px',
  height: '20px',
})

export default InnerLayout
