import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import storage from '@/utils/storage'
import { Functions, Menu } from '#/client/menuAndFunction'

import { RootState } from '..'

const STATE_KEY = 'app'

export interface AppState {
  openingDeviceId?: number
  selectedDeviceId?: number
  remote: string
  token: string
  menus: Menu[]
  functions: Functions
  selectedRoute?: string
}

const initialState: AppState = storage.getItem(STATE_KEY)
  ? {
      ...storage.getItem(STATE_KEY),
      openingDeviceId: undefined,
    }
  : ({
      openingDeviceId: undefined,
      selectedDeviceId: undefined,
      remote: '',
      token: '',
      menus: [],
      functions: {},
      selectedRoute: '',
    } as AppState)

export const appSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateRemote: (state, action: PayloadAction<AppState>) => {
      state.selectedDeviceId = action.payload.selectedDeviceId
      state.remote = action.payload.remote
      state.token = action.payload.token
      state.menus = action.payload.menus
      state.functions = action.payload.functions
      storage.setItem(STATE_KEY, state)
    },
    resetRemote: (state) => {
      state.selectedDeviceId = undefined
      state.remote = ''
      state.token = ''
      state.menus = []
      state.functions = {}
      state.selectedRoute = ''
      storage.setItem(STATE_KEY, state)
    },
    updateOpening: (state, action: PayloadAction<number | undefined>) => {
      state.openingDeviceId = action.payload
    },
    updateSelectedRoute: (state, action: PayloadAction<string | undefined>) => {
      state.selectedRoute = action.payload
    },
  },
})

export const { updateRemote, resetRemote, updateOpening, updateSelectedRoute } = appSlice.actions

export const selectRemote = (state: RootState) => ({
  selectDeviceId: state.app.selectedDeviceId,
  remote: state.app.remote,
  token: state.app.token,
  menus: state.app.menus,
  functions: state.app.functions,
})

export const selectDeviceId = (state: RootState) => state.app.selectedDeviceId

export const selectOpening = (state: RootState) => state.app.openingDeviceId

export default appSlice.reducer
