import { IconMoreStroked } from '@douyinfe/semi-icons'
import { Dropdown, Layout, Nav, Typography } from '@douyinfe/semi-ui'
import { FC, useRef } from 'react'
import { useNavigate } from 'react-router'

import Logo from '@/components/Logo/Logo'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { logout } from '@/store/slices/auth.slice'

import styles from './Header.module.scss'

const Header: FC = () => {
  const user = useAppSelector((state) => state.user?.userInfo)
  const phone = useAppSelector((state) => state.user?.userInfo?.phone)
  const isAdmin = useAppSelector((state) => state.user?.userInfo?.isAdmin)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const ipcRenderer = useRef(window.require?.('electron').ipcRenderer)

  return (
    <Layout.Header className={styles.header}>
      <Nav mode='horizontal' className={styles.nav}>
        <Nav.Header>
          <Logo icon={user?.customer?.icon} />
          <h1 className={styles.title}>{user?.customer?.name}</h1>
        </Nav.Header>
        <Nav.Footer>
          <Typography.Text style={{ cursor: 'pointer' }}>{phone}</Typography.Text>
          <Dropdown
            render={
              <Dropdown.Menu>
                {isAdmin && (
                  <Dropdown.Item
                    onClick={() => {
                      window.open('/enterprise/', '_blank')
                    }}>
                    企业管理
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={async () => {
                    await dispatch(logout())
                    navigate('/login', { replace: true })
                  }}>
                  退出登录
                </Dropdown.Item>
              </Dropdown.Menu>
            }>
            <IconMoreStroked
              style={{ marginLeft: '8px' }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            />
          </Dropdown>
          {/* <div className={styles.divider}></div>
          <Button
            theme='borderless'
            icon={<IconMinus size='default' />}
            className={styles.button}
            onClick={() => {
              ipcRenderer.current?.invoke('win:minimize')
            }}
          />
          <Button
            theme='borderless'
            icon={<IconClose size='default' />}
            className={styles.button}
            onClick={() => {
              ipcRenderer.current?.invoke('win:close')
            }}
          /> */}
        </Nav.Footer>
      </Nav>
    </Layout.Header>
  )
}

export default Header
