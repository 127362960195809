import { Toast } from '@douyinfe/semi-ui'
import axios from 'axios'

import { API_TOKEN_HTTP_HEADER_KEY, API_TOKEN_STORAGE_KEY } from '@/const'

import storage from '../storage'

const service = axios.create({
  // @ts-ignore
  timeout: import.meta.env.VITE_REQUEST_TIME_OUT,
  // @ts-ignore
  baseURL: import.meta.env.VITE_API_BASE_URL,
})

/**
 * request interceptors
 */
service.interceptors.request.use(
  (config) => {
    config.url = (import.meta.env.VITE_API_BASE_URL + encodeURI(config.url as string)).replace(/\/\//g, '')
    const apiToken = storage.getItem(API_TOKEN_STORAGE_KEY)
    if (apiToken) {
      ;(config.headers = config.headers || {})[API_TOKEN_HTTP_HEADER_KEY] = apiToken
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const { result, error } = response.data
    if (result !== '000000') {
      if (result === '000002') {
        try {
          storage.remove(API_TOKEN_STORAGE_KEY)
          location.replace(location.origin + '/login')
        } catch (e) {
          // ignore
        }
      } else {
        Toast.error(error)
      }
      return Promise.reject(error)
    }
    return response.data
  },
  (error) => {
    if (error.response) {
      const token = storage.getItem(API_TOKEN_STORAGE_KEY)
      if (error.response.status === 403) {
        Toast.error('禁止访问')
      }
      if (error.response.status === 401) {
        Toast.error('鉴权失败')
        if (token) {
          // TODO 根据业务决定
        }
      }
    }
    return Promise.reject(error)
  }
)

export default service
