import contactImg from '@/assets/imgs/contact.png'
import subscriptionImg from '@/assets/imgs/subscription.jpg'
import http from '@/utils/request'
import { Toast } from '@douyinfe/semi-ui'
// @ts-ignore
import QRCode from 'qrcode'
import { FC, useState } from 'react'
import { useInterval, useMount } from 'react-use'
import styles from './Contact.module.scss'

const Contact: FC = () => {
  const [bindUrl, setBindUrl] = useState('')

  ////////////////////////
  ////////////////////////

  const refreshCheck = async (): Promise<boolean> => {
    const { data } = await http.get(`/api/wx/bind/check`, {})
    return data
  }

  const refreshUrl = async (): Promise<string> => {
    const { data } = await http.get(`/api/wx/url/bind`, {})
    return data
  }

  useMount(async () => {
    const isBind = await refreshCheck()
    if (!isBind) {
      const url = await refreshUrl()
      setBindUrl(url)
      requestAnimationFrame(() => {
        QRCode.toCanvas(document.getElementById('wxBind'), url, { width: 100, height: 100 }, function (error: any) {
          if (error) console.error(error)
          console.log('success!')
        })
      })
    }
  })

  useInterval(
    async () => {
      if (await refreshCheck()) {
        setBindUrl('')
        Toast.success(`绑定成功`)
      }
    },
    bindUrl ? 1000 : null
  )

  ////////////////////////
  ////////////////////////

  return (
    <div className={styles.container}>
      {bindUrl ? (
        <div className={styles.card}>
          <canvas id='wxBind' style={{ width: '100px', height: '100px' }}></canvas>
          <h1 className={styles.cardTitle}>微信绑定</h1>
        </div>
      ) : null}
      {/* <div className={styles.card}>
        <img src={contactImg} alt='联系我们' className={styles.cardImg} />
        <h1 className={styles.cardTitle}>联系我们</h1>
      </div>
      <div className={styles.card}>
        <img src={subscriptionImg} alt='关注公众号' className={styles.cardImg} />
        <h1 className={styles.cardTitle}>关注公众号</h1>
      </div> */}
    </div>
  )
}

export default Contact
