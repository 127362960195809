import Exception from '@/views/Exception/Exception'
import HomeView from '@/views/Home/Home'
import InnerLayout from '@/views/InnerLayout/InnerLayout'
import Loading from '@/views/Loading/Loading'
import { ConfigProvider } from '@douyinfe/semi-ui'
import { FC, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AuthProvider from './context/auth.context'
import AuthenticatedApp from './views/AuthenticatedApp/AuthenticatedApp'
import Login from './views/Login/Login'
import WebSSH from './views/WebSSH'

const App: FC = () => {
  const [timeZone] = useState('GMT+08:00')

  return (
    <ConfigProvider timeZone={timeZone}>
      <Router>
        <Routes>
          <Route
            path='/'
            element={
              <AuthProvider>
                <AuthenticatedApp />
              </AuthProvider>
            }>
            <Route index element={<HomeView />} />
            <Route path='home' element={<HomeView />} />
            <Route path='device/:id/*' element={<InnerLayout />} />
            <Route path='loading' element={<Loading />} />
            <Route path='exception' element={<Exception />} />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/webssh/:id' element={<WebSSH />} />
        </Routes>
      </Router>
    </ConfigProvider>
  )
}

export default App
