import http from '@/utils/request'
import Socket from '@/utils/socket/socket'
import { Modal } from '@douyinfe/semi-ui'
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import 'xterm/css/xterm.css'

export default class Client {
  display?: Terminal
  socket?: any
  deviceId?: string

  constructor(deviceId: string) {
    this.deviceId = deviceId
    this.socket = new Socket()
  }

  /**
   *
   * @param deviceId
   */
  async connect() {
    await this.socket.connect('api/socket')
    this.socket.sock.onclose = () => {
      Modal.confirm({
        title: '连接已断开,是否重新连接',
        onOk() {
          window.location.reload()
        },
      })
    }
    this.display = new Terminal({
      rows: 37,
      cursorBlink: true, // 光标闪烁
      cursorStyle: 'block', // 光标样式  null | 'block' | 'underline' | 'bar'
      scrollback: 800, //回滚
      tabStopWidth: 8, //制表宽度
    })
    this.socket?.addQueue(`terminal/${this.deviceId}`, this.onReply)
    this.display.open(document.getElementById('xterm') as any)
    const fitAddon = new FitAddon()
    this.display.loadAddon(fitAddon)
    fitAddon.fit()
    this.display.onData(this.onKey)
    http.post(`/api/terminal/connect`, {
      data: {
        deviceId: this.deviceId,
      },
    })
  }

  /**
   *
   */
  async disconnect() {
    this.socket?.remove(`/user/queue/terminal/${this.deviceId}`)
    this.socket?.close()
    await http.post(`/api/terminal/disconnect`, {
      data: {
        deviceId: this.deviceId,
      },
    })
  }

  /**
   *
   */
  onKey = (evt: any) => {
    this.socket?.send(`/app/api/terminal/send`, {
      command: evt,
      deviceId: this.deviceId,
    })
  }

  /**
   *
   */
  onReply = ({ body }: any) => {
    this.display?.write(body)
  }
}
