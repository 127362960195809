import { FC, ReactNode } from 'react'
import { useAsync } from 'react-use'

import { FullPageErrorFallback, FullPageLoading } from '@/components/Common/Common'
import { useAppDispatch } from '@/store/hooks'
import { fetchUserInfo } from '@/store/slices/auth.slice'

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch()
  const { error, loading } = useAsync(() => dispatch(fetchUserInfo()), [])

  if (loading) {
    return <FullPageLoading />
  }

  if (error) {
    return <FullPageErrorFallback error={error as Error} />
  }

  return <div>{children}</div>
}

export default AuthProvider
