import { Toast } from '@douyinfe/semi-ui'
import hotkeys from 'hotkeys-js'

import { API_TOKEN_STORAGE_KEY } from '@/const'
import storage from '@/utils/storage'

hotkeys('t+o+k+e+n', () => {
  const token = storage.getItem(API_TOKEN_STORAGE_KEY)
  const tag = document.createElement('input')
  tag.setAttribute('id', 'token-input')
  tag.value = token
  document.getElementsByTagName('body')[0].appendChild(tag)
  ;(document.getElementById('token-input') as HTMLInputElement)?.select()
  document.execCommand('copy')
  ;(document.getElementById('token-input') as HTMLInputElement)?.remove()
  Toast.success('拷贝 token 成功')
})
