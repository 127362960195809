import { Descriptions, Skeleton } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC } from 'react'
import { useQuery } from 'react-query'

import http from '@/utils/request'

const fetchStat = async () => {
  const { data: ret } = await http.get('api/device/dashboard', {})
  return ret
}

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
`

const PlaceholderItem = styled.div`
  margin-right: 20px;
`

const placeholder = (
  <PlaceholderContainer>
    {new Array(3).fill(undefined).map((_, index) => (
      <PlaceholderItem key={index}>
        <Skeleton.Paragraph rows={1} style={{ width: 80, marginBottom: 10 }} />
        <Skeleton.Title style={{ width: 120 }} />
      </PlaceholderItem>
    ))}
  </PlaceholderContainer>
)

const Stat: FC = () => {
  const { isLoading, isFetching, data } = useQuery<any>('stat', fetchStat, { staleTime: 1000 * 60 })

  return (
    <Skeleton placeholder={placeholder} loading={isLoading || isFetching} active>
      <Descriptions row size='large'>
        <Descriptions.Item itemKey='盒子总数'>
          <span style={{ color: 'rgba(var(--semi-indigo-5), 1)' }}>{data?.total || 0}个</span>
        </Descriptions.Item>
        <Descriptions.Item itemKey='在线'>
          <span style={{ color: 'rgba(var(--semi-green-4), 1)' }}>{data?.totalOnline || 0}个</span>
        </Descriptions.Item>
        <Descriptions.Item itemKey='离线'>
          <span style={{ color: 'rgba(var(--semi-orange-6), 1)' }}>{data?.totalOffline || 0}个</span>
        </Descriptions.Item>
      </Descriptions>
    </Skeleton>
  )
}

export default Stat
