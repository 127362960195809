import * as auth from '@/api/auth.api'
import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

export interface UserState {
  userInfo: auth.UserInfo | null
  token: string
}

const initialState: UserState = {
  userInfo: null,
  token: '',
} as UserState

export const scan = createAsyncThunk('user/scan', async (user: any) => {
  await auth.scanLogin(user)
  return user.token
})

export const login = createAsyncThunk('user/login', async ({ mobile, code }: { mobile: string; code: string }) => {
  const response = await auth.login({ mobile, code })
  return response
})

export const fetchUserInfo = createAsyncThunk('user/bootstrap', async () => {
  const response = await auth.bootstrapUser()
  return response
})

export const logout = createAsyncThunk('user/logout', async () => {
  const response = await auth.logout()
  return response
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<auth.UserInfo>) => {
      state.userInfo = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<UserState>) => {
    builder.addCase(scan.fulfilled, (state: UserState, { payload }) => {
      state.token = payload
    })
    builder.addCase(login.fulfilled, (state: UserState, { payload }) => {
      state.token = payload
    })
    builder.addCase(fetchUserInfo.fulfilled, (state: UserState, { payload }) => {
      state.userInfo = payload
    })
    builder.addCase(logout.fulfilled, (state: UserState) => {
      state.userInfo = null
    })
  },
})

export const { updateUserInfo } = userSlice.actions

export const selectUserInfo = (state: RootState) => state.user.userInfo

export default userSlice.reducer
