import { Avatar, Badge } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC } from 'react'

const AvatarArea: FC<{ device: Partial<Device> }> = ({ device }) => {
  return (
    <Container>
      <Badge position='rightBottom'>
        <Avatar size='default' color={device.online ? 'teal' : 'grey'}>
          <Title>{device.type}</Title>
        </Avatar>
      </Badge>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Title = styled.span`
  font-size: 12px;
  white-space: nowrap;
`

export default AvatarArea
