import { FC } from 'react'

import IframeView from '../Iframe/Iframe'
import MicroApp from '../MicroApp/MicroApp'

const SubApp: FC<{ baseRoute?: string; routePath?: string; isMicroApp?: boolean }> = ({
  baseRoute,
  routePath,
  isMicroApp,
}) => {
  return isMicroApp ? <MicroApp baseRouter={baseRoute} /> : <IframeView routePath={routePath} />
}

export default SubApp
