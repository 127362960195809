import { IconMoreStroked } from '@douyinfe/semi-icons'
import { Dropdown, Toast } from '@douyinfe/semi-ui'
import copy from 'copy-to-clipboard'
import { FC, useRef } from 'react'
import { useNavigate } from 'react-router'

import { useAuth } from '@/hooks/useAuth'
import { isDef } from '@/utils/is'
import storage from '@/utils/storage'

import { EditDeviceForm } from '../DeviceModal/EditDeviceModal'

const Actions: FC<{
  device: Partial<Device>
  vpnConnectedDeviceId: Nullable<number>
  handleClickVpn: () => void
  handleStopVpn: () => void
  handleClickEdit?: (device: EditDeviceForm) => void
  handleClickDelete?: (id: number) => void
}> = ({ device, vpnConnectedDeviceId, handleClickVpn, handleStopVpn, handleClickEdit, handleClickDelete }) => {
  const { userInfo } = useAuth()
  const navigate = useNavigate()
  const ipcRenderer = useRef(window.require?.('electron').ipcRenderer)
  return (
    <div>
      <Dropdown
        trigger={'hover'}
        position={'bottomLeft'}
        stopPropagation={true}
        render={
          <Dropdown.Menu>
            {isDef(ipcRenderer.current) && (
              <>
                <Dropdown.Item
                  disabled={isDef(vpnConnectedDeviceId) && vpnConnectedDeviceId === device.id}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClickVpn && handleClickVpn()
                  }}>
                  VPN设置
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={
                    !isDef(vpnConnectedDeviceId) || (isDef(vpnConnectedDeviceId) && vpnConnectedDeviceId !== device.id)
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    handleStopVpn && handleStopVpn()
                  }}>
                  断开VPN
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation()
                handleClickEdit?.(device as EditDeviceForm)
              }}>
              修改设备
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                copy(device.sn as string)
                Toast.success(`已复制SN: ${device.sn}`)
              }}>
              复制SN
            </Dropdown.Item>
            {userInfo?.enableWebShell && (
              <Dropdown.Item
                onClick={(e) => {
                  window.open(
                    `http://${device.sn}.ssh.way-win.cn:8889/?client=true&deviceId=${device.id}&userId=${
                      userInfo.id
                    }&title=${device.name}&token=${storage.getItem('token')}`
                  )
                }}>
                WebSSH
              </Dropdown.Item>
            )}
            {/* <Dropdown.Item
              type='warning'
              onClick={(e) => {
                e.stopPropagation()
                Modal.warning({
                  title: '确定删除此设备吗？',
                  content: <Typography.Text>此操作无法撤销</Typography.Text>,
                  centered: true,
                  cancelText: '确定',
                  okText: '我再考虑考虑',
                  onCancel: (e) => {
                    e.stopPropagation()
                    handleClickDelete?.(device.id as number)
                  },
                })
              }}>
              删除设备
            </Dropdown.Item> */}
          </Dropdown.Menu>
        }>
        <IconMoreStroked
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        />
      </Dropdown>
    </div>
  )
}

export default Actions
