import { useAppDispatch } from '@/store/hooks'
import { scan } from '@/store/slices/auth.slice'
import { checkMobileIsValid } from '@/utils'
import http from '@/utils/request'
import { Button, Form, InputGroup, Modal, Toast, useFieldState } from '@douyinfe/semi-ui'
import { FC, useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

interface IProps {
  scan: string
  visible: boolean
  onClose: () => void
}

const Bind: FC<IProps> = (prop) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [seconds, setSeconds] = useState<number>(60)
  const [timing, setTiming] = useState<boolean>(false)
  const start = useRef<number>(0)
  const timer = useRef<ReturnType<typeof requestAnimationFrame> | null>(null)

  const startTimer = useCallback(() => {
    timer.current = requestAnimationFrame(() => {
      const current = +new Date()
      const diff = 60 - Math.round((current - start.current) / 1000)
      if (diff > 0 && diff <= 60) {
        setSeconds(diff)
        startTimer()
      } else if (diff <= 0) {
        cancelAnimationFrame(timer.current as ReturnType<typeof requestAnimationFrame>)
        setTiming(false)
      }
    })
  }, [])

  const getCode = useCallback(async (mobile: string) => {
    if (!mobile || !checkMobileIsValid(mobile)) {
      Toast.error('请输入正确的手机号')
      return
    }
    await http
      .post('api/wx/url/login/bind/send', {
        data: {
          mobile,
        },
      })
      .catch(() => {
        Toast.error('获取验证码失败，请重试')
      })
    start.current = +new Date()
    setSeconds(60)
    setTiming(true)
    startTimer()
  }, [])

  const handleSubmit = useCallback(
    async (values: { mobile: string; code: string }) => {
      const { data } = await http.post(`/api/wx/url/login/bind`, { data: { ...values, scan: prop.scan } })
      Toast.success(`绑定成功`)
      await dispatch(scan(data))
      cancelAnimationFrame(timer.current as ReturnType<typeof requestAnimationFrame>)
      navigate('/home')
    },
    [prop.scan]
  )

  const VerifyButton = () => {
    const fieldState = useFieldState('mobile')
    return (
      <Button
        type='tertiary'
        size='large'
        disabled={timing}
        style={{ width: '120px', marginLeft: '8px' }}
        onClick={() => {
          !timing && getCode(fieldState.value)
        }}>
        {timing ? `${seconds}秒后可获取` : '发送验证码'}
      </Button>
    )
  }

  return (
    <Modal
      title='尚未绑定手机号, 请进行绑定'
      closable={false}
      visible={prop.visible}
      okText='绑定'
      width={380}
      onCancel={prop.onClose}
      footer={<div />}>
      <Form onSubmit={(values) => handleSubmit(values as any)} style={{ width: '320px' }}>
        {({ formState, values, formApi }) => (
          <>
            <Form.Input
              field='mobile'
              label='您的手机号'
              noLabel={true}
              style={{ width: '320px' }}
              size='large'
              placeholder='输入您的手机号'></Form.Input>
            <InputGroup style={{ width: '320px', marginTop: '20px' }} size='large'>
              <Form.Input
                field='code'
                label='短信验证码'
                size='large'
                noLabel={true}
                style={{ width: '192px' }}
                placeholder='输入验证码'></Form.Input>
              <VerifyButton />
            </InputGroup>
            <Button
              disabled={!values.mobile || !values.code}
              htmlType='submit'
              type='tertiary'
              size='large'
              block
              style={{ marginTop: '32px' }}>
              绑定
            </Button>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default Bind
