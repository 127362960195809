import { Button, Form, Modal } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC, useCallback, useEffect, useState } from 'react'

import http from '@/utils/request'

export type GroupSelectOption = Pick<DeviceGroup, 'id' | 'name'>

export interface EditDeviceModalParams {
  visible: boolean
  device: EditDeviceForm
  groups?: GroupSelectOption[]
  handleOk?: () => void
  handleCancel?: () => void
}

export type EditDeviceForm = Pick<Device, 'id' | 'name' | 'groupId'>

export type EditDeviceFormValidate = { [key in keyof EditDeviceForm]?: string }

function syncValidate(values: Record<string, any>) {
  const errors: EditDeviceFormValidate = {}
  if (!(values as EditDeviceForm).name) {
    errors.name = '请填写设备名称'
  }
  if (!(values as EditDeviceForm).groupId) {
    errors.groupId = '请选择设备分组'
  }
  return errors
}

async function saveDevice(id: number, name: string, groupId: number) {
  return await http.post('api/device/edit', { data: { id, name, groupId } })
}

async function groupList() {
  return await http.post('api/device/group/find/all', { data: {} })
}

const EditDeviceModal: FC<EditDeviceModalParams> = ({ visible, device, handleOk, handleCancel }) => {
  const submit = useCallback(
    async (name: string, groupId: number) => {
      await saveDevice(device.id, name, groupId)
      handleOk?.()
    },
    [device.id]
  )
  const [groups, setGroups] = useState([] as any[])

  useEffect(() => {
    groupList().then((res) => {
      setGroups(res.data)
    })
  }, [device.id])

  return (
    <Modal
      title={`修改设备[${device.name}]`}
      visible={visible}
      mask={false}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={null}>
      <Container>
        <Form validateFields={syncValidate} labelPosition='left' labelWidth={80}>
          {({ formState, values, formApi }) => (
            <>
              <Form.Input
                field='name'
                label='名称'
                initValue={device.name}
                placeholder='请填写设备名称'
                trigger='blur'></Form.Input>
              <Form.Select
                field='groupId'
                label='设备分组'
                initValue={device.groupId}
                placeholder='请选择设备分组'
                style={{ width: '100%' }}>
                {groups?.map((group) => (
                  <Form.Select.Option key={group.id} value={group.id}>
                    {group.name}
                  </Form.Select.Option>
                ))}
              </Form.Select>
              <div style={{ textAlign: 'right', marginTop: '10px' }}>
                <Button
                  type='primary'
                  disabled={!values.name || !values.groupId}
                  onClick={() => submit(values.name, values.groupId)}>
                  提交
                </Button>
              </div>
            </>
          )}
        </Form>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 32px;
`

export default EditDeviceModal
