import { Banner, Button, Form, Modal, Typography } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC, useState } from 'react'

import http from '@/utils/request'

export interface VpnConfParams {
  deviceId?: number
  vpnInfo?: DeviceVpnInfo
  visible: boolean
  handleOk?: (values: Record<string, any>) => void
  handleCancel?: () => void
}

export interface VpnConf {
  vpnIp: string
  mask: string
}

export type VpnFormValidate = { [key in keyof VpnConf]?: string }

function syncValidate(values: Record<string, any>) {
  const errors: VpnFormValidate = {}
  if (!(values as VpnConf).vpnIp) {
    errors.vpnIp = '请填写ip地址'
  }
  if (!(values as VpnConf).mask) {
    errors.mask = '请填写子网掩码'
  }
  console.log(errors)
  return errors
}

function isValidIP(ip: string): boolean {
  const IP_REGEX =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return IP_REGEX.test(ip)
}

function isValidSubnetMask(subnetMask: string): boolean {
  const SUBNET_MASK_REGEX = /^(255\.){3}(255|254|252|248|240|224|192|128|0)+(\.0)*$/
  return SUBNET_MASK_REGEX.test(subnetMask)
}

const VpnConfView: FC<VpnConfParams> = ({ deviceId, vpnInfo, visible, handleOk, handleCancel }) => {
  let ethInfoList = vpnInfo?.ethList.map((eth) => {
    let ethInfoArray = eth.split('#')
    return {
      name: ethInfoArray.length > 0 ? ethInfoArray[0] : '',
      ip: ethInfoArray.length > 1 ? ethInfoArray[1] : '0.0.0.0',
      mask: ethInfoArray.length > 2 ? ethInfoArray[2] : '255.255.255.255',
    }
  })
  const [connectLoading, setConnectLoading] = useState(false)
  const { Text } = Typography
  return (
    <Modal title='VPN设置' visible={visible} mask={false} onOk={handleOk} onCancel={handleCancel} footer={null}>
      <Container>
        <Form validateFields={syncValidate} labelPosition='left' labelWidth={80}>
          {({ formState, values, formApi }) => (
            <>
              {vpnInfo?.metas?.status == 'online' && (
                <Banner
                  type='info'
                  bordered
                  closeIcon={null}
                  description={
                    <div>
                      设备VPN服务已开启在 <Text style={{ color: 'aqua' }}>{vpnInfo?.metas?.eth}</Text> 网卡上
                      <br />
                      正在连接此设备的用户数目： <Text style={{ color: 'aqua' }}>{vpnInfo?.metas?.cur_conns}</Text> 个
                      <br />
                      <br />
                      <Button
                        size={'small'}
                        type={'primary'}
                        onClick={() => {
                          formApi.setValue('eth', vpnInfo?.metas?.eth)
                          let selEthInfo = ethInfoList?.find((ethInfo) => ethInfo.name === vpnInfo?.metas?.eth)
                          formApi.setValue('vpnIp', selEthInfo?.ip.substring(0, selEthInfo?.ip.lastIndexOf('.') + 1))
                          formApi.setError('vpnIp', '请将IP补充完整，不能和网卡的IP相同')
                          formApi.setValue('mask', selEthInfo?.mask)
                        }}>
                        继续使用此服务
                      </Button>
                      <Text> 或 </Text>
                      在下方选取不同网卡重新连接，此操作会重新配置VPN服务，正在连接此设备的用户将会被强制下线
                    </div>
                  }
                />
              )}
              <Form.Select
                field='eth'
                label='设备网卡'
                placeholder='请选择连接网卡，默认使用设备当前配置'
                onSelect={(curVal) => {
                  let selEthInfo = ethInfoList?.find((ethInfo) => ethInfo.name === curVal)
                  formApi.setValue('vpnIp', selEthInfo?.ip.substring(0, selEthInfo?.ip.lastIndexOf('.') + 1))
                  formApi.setError('vpnIp', '请将IP补充完整，不能和网卡的IP相同')
                  formApi.setValue('mask', selEthInfo?.mask)
                }}
                style={{ width: '100%' }}>
                {ethInfoList?.map((ethInfo) => (
                  <Form.Select.Option key={ethInfo.name} value={ethInfo.name}>
                    <span style={{ marginLeft: '4px', marginRight: '4px', fontWeight: 'bold', color: 'aqua' }}>
                      {ethInfo.name}
                    </span>
                    [<span style={{ marginLeft: '4px', marginRight: '4px' }}>{ethInfo.ip}</span>/
                    <span style={{ marginLeft: '4px', marginRight: '4px' }}> {ethInfo.mask} </span>]
                  </Form.Select.Option>
                ))}
              </Form.Select>
              <Form.Input
                field='vpnIp'
                label='IP地址'
                placeholder='请输入IP'
                trigger='blur'
                validate={(valVpnIp) => (isValidIP(valVpnIp) ? '' : '请检查IP格式是否正确')}></Form.Input>
              <Form.Input
                field='mask'
                label='子网掩码'
                placeholder='请输入子网掩码'
                trigger='blur'
                validate={(valMask) => (isValidSubnetMask(valMask) ? '' : '请检查子网掩码格式是否正确')}></Form.Input>
              <div style={{ textAlign: 'right', marginTop: '10px' }}>
                <Button
                  type='primary'
                  disabled={
                    !values.vpnIp || !values.mask || !isValidIP(values.vpnIp) || !isValidSubnetMask(values.mask)
                  }
                  loading={connectLoading}
                  onClick={async () => {
                    let timeoutToConnect = 100
                    setConnectLoading(true)
                    if (
                      vpnInfo?.metas?.status == 'online' &&
                      vpnInfo?.metas?.eth != null &&
                      values.eth != vpnInfo?.metas?.eth
                    ) {
                      timeoutToConnect = 5000
                      const { data: ret } = await http.post('api/vpn/disconnect', {
                        data: {
                          id: deviceId,
                          eth: values.eth,
                        },
                      })
                    }
                    setTimeout(async () => {
                      setConnectLoading(false)
                      handleOk?.({
                        ...values,
                      })
                    }, timeoutToConnect)
                  }}>
                  开始连接
                </Button>
              </div>
            </>
          )}
        </Form>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 32px;
`

export default VpnConfView
