import { Descriptions, Skeleton, Typography } from '@douyinfe/semi-ui'
import styled from '@emotion/styled'
import { FC } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import http from '@/utils/request'

export interface DeviceInfo {
  flowPlan: FlowPlan
  id: number
  name: string
  online: boolean
  sim: Sim
  sn: string
  status: string
  statusText: string
  type: string
  wifi: boolean
}

export interface Sim {
  carrier: string
  dayUsage: number
  id: string
  monthUsage: number
  totalUsage: number
}

export interface FlowPlan {
  id: number
  name: string
}

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
`

const PlaceholderItem = styled.div`
  margin-right: 20px;
`

const placeholder = (
  <PlaceholderContainer>
    {new Array(5).fill(undefined).map((_, index) => (
      <PlaceholderItem key={index}>
        <Skeleton.Paragraph rows={1} style={{ width: 80, marginBottom: 10 }} />
        <Skeleton.Title style={{ width: 120 }} />
      </PlaceholderItem>
    ))}
  </PlaceholderContainer>
)

const fetchDeviceInfo = async (id: number) => {
  const { data: ret } = await http.get(`api/device/info/${id}`, {})
  return ret
}

const Settings: FC = () => {
  const { id } = useParams()

  const {
    isLoading,
    isFetching,
    data: device,
  } = useQuery<DeviceInfo>('device-info', async () => await fetchDeviceInfo(Number(id)))

  return (
    <Page>
      <Typography.Title heading={5}>基本信息</Typography.Title>
      <br />
      <Container>
        <Skeleton placeholder={placeholder} loading={isLoading || isFetching} active>
          <Descriptions row>
            <Descriptions.Item itemKey='设备名称'>
              <Typography.Text copyable>{device?.name || '-'}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item itemKey='SN号'>
              <Typography.Text copyable>{device?.sn || '-'}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item itemKey='型号'>
              <Typography.Text>{device?.type || '-'}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item itemKey='联网方式'>
              <Typography.Text>{device?.wifi ? '网线' : '4G' || '-'}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item itemKey='状态'>
              <Typography.Text>{device?.statusText || '-'}</Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Skeleton>
      </Container>
      <br />
      <Typography.Title heading={5}>流量信息</Typography.Title>
      <br />
      <Container>
        <Skeleton placeholder={placeholder} loading={isLoading || isFetching} active>
          <Descriptions row>
            <Descriptions.Item itemKey='运营商'>
              <Typography.Text>{device?.sim?.carrier || '-'}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item itemKey='ICCID'>
              <Typography.Text>{device?.sim?.id || '-'}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item itemKey='流量套餐'>
              <Typography.Text>{device?.flowPlan?.name || '-'}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item itemKey='本月流量'>
              <Typography.Text>{device?.sim?.monthUsage || '-'}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item itemKey='累计流量'>
              <Typography.Text>{device?.sim?.totalUsage || '-'}</Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Skeleton>
      </Container>
    </Page>
  )
}

const Page = styled.div`
  padding: 24px;
`

const Container = styled.div`
  border-radius: 10px;
  border: 1px solid var(--semi-color-border);
  padding: 24px;
`

export default Settings
