import { useCallback } from 'react'

import { AuthForm } from '@/api/auth.api'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import * as authStore from '@/store/slices/auth.slice'

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector(authStore.selectUserInfo)

  const login = useCallback((form: AuthForm) => dispatch(authStore.login(form)), [dispatch])
  const logout = useCallback(() => dispatch(authStore.logout()), [dispatch])

  return {
    userInfo,
    login,
    logout,
  }
}
