import { FC } from 'react'

const Refresh: FC<{ animation: boolean; onClick: (e: MouseEvent) => any }> = (props) => {
  return (
    <svg
      viewBox='0 0 1024 1024'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      p-id='3075'
      width='20'
      height='20'
      style={{ cursor: 'pointer', color: '#eee' }}>
      <g>
        <path
          d='M909.312 596.48c0 2.048 0 3.072-0.512 3.584-22.528 94.208-69.632 170.496-141.312 228.864-71.68 58.368-155.648 87.552-251.904 87.552-51.2 0-100.864-9.728-148.992-29.184-48.128-19.456-90.624-47.104-128.512-82.432l-68.096 68.096c-6.656 6.656-14.848 9.728-23.552 9.728s-16.896-3.072-23.552-9.728c-6.656-6.656-9.728-14.848-9.728-23.552v-236.032c0-9.216 3.072-16.896 9.728-23.552 6.656-6.656 14.336-9.728 23.552-9.728h236.032c9.216 0 16.896 3.072 23.552 9.728 6.656 6.656 9.728 14.848 9.728 23.552 0 9.216-3.072 16.896-9.728 23.552l-72.192 72.192c25.088 23.04 53.248 40.96 84.992 53.76 31.744 12.8 64.512 18.944 98.304 18.944 47.104 0 91.136-11.264 131.584-34.304 40.96-23.04 73.216-54.272 97.792-94.208 3.584-6.144 13.312-26.624 27.648-61.44 2.56-8.192 8.192-12.288 15.872-12.288h101.376c4.608 0 8.704 1.536 11.776 5.12 4.096 3.072 6.144 7.168 6.144 11.776z m12.8-421.376v236.032c0 9.216-3.072 16.896-9.728 23.552-6.656 6.656-14.848 9.728-23.552 9.728h-236.032c-9.216 0-16.896-3.072-23.552-9.728-6.656-6.656-9.728-14.336-9.728-23.552s3.072-16.896 9.728-23.552L701.44 314.368c-52.224-48.128-113.664-72.192-183.808-72.192-47.104 0-91.136 11.264-131.584 34.304S312.832 330.752 288.256 370.688c-3.584 6.144-13.312 26.624-27.648 61.44-2.56 8.192-8.192 12.288-15.872 12.288H139.264c-4.608 0-8.704-1.536-11.776-5.12-3.072-3.072-5.12-7.168-5.12-11.776v-3.584c23.04-94.208 70.144-170.496 142.336-228.864S421.376 107.52 517.632 107.52c51.2 0 101.376 9.728 149.504 29.184s91.648 47.104 129.024 82.432l68.608-68.096c6.656-6.656 14.336-9.728 23.552-9.728 9.216 0 16.896 3.072 23.552 9.728 7.168 6.656 10.24 14.848 10.24 24.064z'
          fill='currentColor'
          p-id='3076'></path>
        {props.animation && (
          <animateTransform
            id='forward-circle'
            attributeName='transform'
            type='rotate'
            values='0 512 512;360 512 512'
            dur='1s'
            repeatCount='indefinite'
          />
        )}
      </g>
    </svg>
  )
}

export default Refresh
